import React from 'react';
import { Button } from './Button'
import styled from 'styled-components'

const PaginationComponent = ({ className, currentPage, numPages }) => {
	const newerPage = currentPage - 1
	const olderPage = currentPage + 1

	const newerPagePath = newerPage === 1 ? '/' : `/page/${newerPage}`
	const olderPagePath = `/page/${olderPage}`

	return (
		<div className={className}>
          {newerPage > 0 ? (
            <Button className="newer-posts" link={newerPagePath}>Newer Posts</Button>
           
          ) : (
            <div />
          )}
          {olderPage <= numPages ? (
            <Button className="older-posts" link={olderPagePath}>Older Posts</Button>
          ) : (
            <div />
          )}
       </div>
	)
}

const StyledPaginationComponent = styled(PaginationComponent)`
	a {
    border: 4px solid var(--text-color);
    color: white;
		text-decoration: none;
		display: block;
	}
`

export const Pagination = props => (
	<StyledPaginationComponent {...props} />
)