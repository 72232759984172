import React from 'react'
import styled from 'styled-components'

const StyledSearchform = styled.div`
	//display: grid;
   // grid-template-columns: 7fr 3fr;
    //column-gap: .5em;

   & input[type="text"]::-webkit-input-placeholder {
		   //font-size: 0.75em;
		  // color: red;
    	
    }
	& input[type="text"],
	& input[type="submit"] {
		border: 4px solid var(--text-color);
		 border-radius: .25em;
		 padding: 1em;
	}
`

const Searchform = ({ className, textValue, handleChange }) => {
	return (
		<StyledSearchform className={className}>
			<input type="text" onChange={handleChange} value={textValue} className="text" placeholder="記事を検索" />
			<input type="submit" value="検索" />
		</StyledSearchform>
	);
}

export default Searchform

/*
Gatsby+Contentfulブログの記事一覧ページにもっと見るボタンを実装する

もっと見るボタンで次のページを表示する

フィルター機能を実装
##
*/
