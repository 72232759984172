import React, { memo } from 'react'
import styled from 'styled-components'
import { ArticleSummary } from './ArticleSummary'
import { Button } from './Button'
import useFilteredArticleSummaries from '../hooks/useFilteredArticleSummaries'

const StyledFilteredArticleSummaries = styled.div`
	& .article-summary {
		
	    //&:nth-of-type(n+${({ skip }) => skip}) {
	    //	display: none;
	    //}
	    
	}
`

const StyledButton = styled(Button)`
	border: 2px solid var(--text-color);
	background-color: white;//orange;//#ff2e5f;
	color: var(--text-color);
	font-size: .8em;
	font-weight: 900;
`

const FilteredArticleSummaries = memo(({ className, filteredArticleSummaries }) => {//, articleSummaries, searchWord, postsPerPage }) => {
	/*const [
		filteredArticleSummaries, 
		skip, 
		limit, 
		seeMore
	] = useFilteredArticleSummaries(articleSummaries, searchWord, postsPerPage)*/

	return (
		<>
			<StyledFilteredArticleSummaries className={className} skip={filteredArticleSummaries.skip}>
				{
					filteredArticleSummaries.articleSummaries.slice(0, filteredArticleSummaries.skip).map((a, i) => (
		          		<ArticleSummary className="article-summary" key={i} articleSummary={a} />
		        	))
		        }
		    </StyledFilteredArticleSummaries>
		    {
		    	filteredArticleSummaries.limit > 0 && 
		    		<div css={`text-align: center`}>
		    			<StyledButton onClick={filteredArticleSummaries.seeMore}>{`もっと見る（あと${filteredArticleSummaries.limit}記事）`}</StyledButton>
		    		</div>
			}
		</>
	)
})

export default FilteredArticleSummaries