import React, { useState, useCallback, useEffect } from 'react'

import styled from 'styled-components'
import { Link, withPrefix } from 'gatsby'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faSearch } from "@fortawesome/free-solid-svg-icons"

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Button } from '../components/Button'
import { Header } from '../components/header'
import { Footer } from '../components/Footer'
import { Tags } from '../components/Tags'
import Searchform from '../components/Searchform'
import InputText from '../components/InputText'
import ArticleSummaries from '../components/ArticleSummaries'
import FilteredArticleSummaries from '../components/FilteredArticleSummaries'
import { Adsense } from '../components/Adsense'
import { Page } from '../components/Page'
import { Pagination } from '../components/Pagination'
import { Icon } from '../components/Icon'
import getIcon from '../utils/getIcon'
import { Author } from '../components/Author'
import { SiteTitle } from '../components/SiteTitle'
import { Copyright } from '../components/Copyright'
import { Contacts } from '../components/Contacts'
import { Categories } from '../components/Categories'
import { Menu } from '../components/Menu'
import { HumburgerMenuButton, HumburgerMenu } from '../components/HumburgerMenu'
import { DarkModeSwitchButton } from '../components/DarkModeSwitchButton'
import { AutoPager } from '../components/AutoPager'
import ToggleSwitchButton from '../components/ToggleSwitchButton'

import useSiteMetadata from '../hooks/useSiteMetadata'
import useSearchform from '../hooks/useSearchform'
import useAutoPager from '../hooks/useAutoPager'
import useArticleSummaries from '../hooks/useArticleSummaries';
import useFilteredArticleSummaries from '../hooks/useFilteredArticleSummaries';
import useTagsList from '../hooks/useTagsList'
import useCategoriesList from '../hooks/useCategoriesList'
import useHumburgerMenu from '../hooks/useHumburgerMenu'
import useDarkModeButton from '../hooks/useDarkModeButton'

const StyledBlogPage = styled.div `
	//color: var(--text-color);
	 max-width: 66.875rem;
  margin-left: auto;
  margin-right: auto;
& .page-body {
	display: grid;
	grid-template-columns: 1fr;
		row-gap: 1.4em;
		padding: 2em;
}
	& .article-summaries {
		display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.4em;
  row-gap: 1.4em;
	}

	& .pagination {
	    display: flex;
	    justify-content: space-between;
	    margin-top: 2em;

	}

	& .ads {
		&-index-top {
			margin-bottom: 2em;
		}

		&-index-bottom {
			margin-top: 2em;
		}
	}
& .container {

@media screen and (max-width: 780px) {
	width: 100%;
}
		padding: 2em 0;
		//width: 70%;
	}
	& .footer {		
		 grid-row: 2 / 3;
    grid-column: 2 / 3;

    & .tags {
			//justify-content: center;
			& .tag {
				background-color: #1a3a8a;
				color: white;
				& .count {
					background-color: #122961;
					color: inherit;
				}
			}
		}
	}

`

const StickySideHeader = styled.div `
	box-sizing: border-box;
    height: 100vh;
   position: sticky;
    top: 0;
    z-index: 10;
  padding: 0;
 //width: 30%;
    @media screen and (max-width: 780px) {
      height: auto;
      padding: 0;
      width: 100%;
    }
    
`

const SearchIcon = styled.div`
	//padding: 1em;
`

const ArticleSearchform = styled.div`
//background-color: orange;
//border: 2px solid #333;
border-radius: 2em;
display: ${({isShowSearchform}) => isShowSearchform ? 'grid' : 'none'};
//display: grid;
	grid-template-columns: 1em auto;
	column-gap: .5em;
//display: flex;
//align-items: center;
border: ${({ isFocus }) => isFocus ? `2px solid #e6581c` : `2px solid var(--text-color)`};
height: 4em;
margin-bottom: 2em;
padding: 1em;
transition: .5s border ease;
 //flex-wrap: wrap;
 font-size: .65em;
 width: 100%;

 & input[type="text"]::placeholder {
		   //font-size: 0.75em;
		   color: #555;
    	
    }

 @media screen and (max-width: 780px) {
 	//display: ${({isShowSearchform}) => isShowSearchform ? 'flex' : 'none'};
 	margin-bottom: 0;
 }
	> .search-icon {
		${({ isFocus }) => isFocus && `color: #e6581c;`}
//padding: 1em;
		//flex-grow: 1;
		text-align:center;
		transition: .5s color ease;
		//width: 1em;
	}

	> .search {
		background-color: transparent;
		border: none;
		//flex-grow: 9;
		//padding: 1em;

		//padding-left: 0;
//width: auto;
		&:focus {
		border: none;
			
			outline: 0;
		}
	}
`

const SpHeader = styled.div`
  

  @media screen and (max-width: 780px) {
      display: flex;
    //justify-content: space-between;
    align-items: center;
    width: 100%;
    }

    & .sp-header-items {
    	display: flex;
    	justify-content: center;
    	align-items: center;
    	//margin-left: auto;
    	margin-bottom: 2em;

    	@media screen and (max-width: 780px) {
    		margin-left: auto;
    		margin-bottom: 0;

    		& .searchform-open-button {
    			margin-left: 1.2em;
	    	}

	    	& .humburger-menu-button {
	    		margin-left: 1.2em;
	    	}

	    	& .dark-mode-switch-button {
	    		//margin-right: 0;
	    	}
    	}

    	& .searchform-open-button {
    		margin-left: .5em;
    	}

    	& .dark-mode-switch-button {
    		//margin-right: .5em;
    	}
    }
`

const SeachformOpenButton = styled.div`
	//border: 2px solid #333;
	//border-radius: 100%;
	//display: none;
	//display: flex;
	//align-items: center;
	//justify-content: center;
	//margin-bottom: 2em;
	cursor: pointer;
	//font-size: 1.4em;
	//height: 2em;
	//width: 2em;
	@media screen and (max-width: 780px) {
	//	display: block;
	}
`

const BlogPage = props => {
    const { 
    	title,
    	googleAdsense,
    	author,
    	image, 
    	copyright,
    	menu
    } = useSiteMetadata()
    const [textValue, handleChange] = useSearchform()

    const filteredArticleSummaries = useFilteredArticleSummaries(props.pageContext.articleSummaries, textValue)//, 4)
   // const articleSummaries = useArticleSummaries()
	//const { skip, limit, seeMore, currentArticleSummaries } = useAutoPager(articleSummaries, postsPerPage)///4)

const articleSummariesPager = useAutoPager(props.pageContext.articleSummaries, 4)
const filteredArticleSummariesPager = useAutoPager(filteredArticleSummaries, 4)


//const [toggleMenu, menuElement] = useHumburgerMenu()

    const [isFocus, setIsFocus] = useState(false)
    const [isShowSearchform, setIsShowSearchform] = useState(false)

    const handleFocus = useCallback(e => setIsFocus(true), [])
    const handleBlur = useCallback(e => setIsFocus(false), [])
    const handleClick = useCallback(e => {
    	if(isShowSearchform) {
    		setIsShowSearchform(false)
    	}else{
    		setIsShowSearchform(true)
    	}
    }, [isShowSearchform])

    const tags = useTagsList()

  const categories = useCategoriesList()
const [toggleMenu, isOpen, menuElement] = useHumburgerMenu()
  const [
  isDarkMode,
  	checkboxElement,
		handleChangeDarkMode
] = useDarkModeButton()
//const articleSummaries = useArticleSummaries()
useEffect(() => {
	console.log(filteredArticleSummariesPager.articleSummaries)
}, [filteredArticleSummariesPager.articleSummaries])
    return (
        <StyledBlogPage>
		<Layout className={props.className} layout="row">
			<SEO title={title} />
			<StickySideHeader>
				<Header className="header" isSticky={true}>
					<SpHeader>
						<SiteTitle className="site-title" title={title} linkTo="/" logoUrl={isDarkMode ? withPrefix("images/logo_sab_dark.png") : withPrefix("images/logo_sab.png")} />      
						<div className="sp-header-items">
						<DarkModeSwitchButton className="dark-mode-switch-button" size=".8em" handleChange={handleChangeDarkMode} ref={checkboxElement} />
						<SeachformOpenButton className="searchform-open-button" onClick={handleClick}>
							<Icon icon={faSearch} size="lg" />
						</SeachformOpenButton>
						<HumburgerMenuButton className="humburger-menu-button" isOpen={isOpen} handleClick={toggleMenu} size=".6em" />
						</div>
					</SpHeader>
					<ArticleSearchform className="searchform" isFocus={isFocus} isShowSearchform={isShowSearchform}>
						<SearchIcon className="search-icon">
							<Icon icon={faSearch} />
						</SearchIcon>
						<InputText
							className="search"
							type="text"
							onChange={handleChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							value={textValue}
							placeholder="記事を検索"
						/>
					</ArticleSearchform>
					<HumburgerMenu isOpen={isOpen}>
						<Categories className="categories" categories={categories} />
						<Menu className="menu" menu={menu} />
					</HumburgerMenu>
					<Contacts className="contacts" contacts={author.contacts} />
					<Copyright className="copyright" copyright={copyright} />
				</Header>
			</StickySideHeader>
			<Page className="container">
				<Adsense currentPath={props.pageContext.basePath} className="ads ads-index-top" client={googleAdsense.client} slot={googleAdsense.slot.indexTop} />
				<div style={{ textAlign: 'center' }}>{props.pageContext.basePath.match(/[^/]+?$/)}</div>
				{
					textValue.trim().length > 0 ?
						<>
							<ArticleSummaries
								className="article-summaries"
								articleSummaries={filteredArticleSummariesPager.articleSummaries}
								//skip={filteredArticleSummaries.skip}
								//postsPerPage={4}
							/>
							<AutoPager
								className="more-link"
								limit={filteredArticleSummariesPager.limit}
								handleClick={filteredArticleSummariesPager.seeMore}
							/>
						</>
					: 
						<>
							<ArticleSummaries 
					        	className="article-summaries"
					        	articleSummaries={articleSummariesPager.articleSummaries}
					        	//skip={articleSummaries.skip}
					        	//postsPerPage={4}
					        />
					         <AutoPager
								className="more-link"
								limit={articleSummariesPager.limit}
								handleClick={articleSummariesPager.seeMore}
							/>
						</>
				}
			    <Pagination className="pagination" currentPage={props.pageContext.currentPage} numPages={props.pageContext.numPages} />
				<Adsense currentPath={props.pageContext.basePath} className="ads ads-index-bottom" client={googleAdsense.client} slot={googleAdsense.slot.indexBottom} />

			</Page>
		</Layout>
		</StyledBlogPage>
    )
}

export default BlogPage
//<DarkModeSwitchButton className="dark-mode-switch-button" checkboxElement={checkboxElement} handleChange={handleChangeDarkMode} />
// textValue.trim().length > 0 ? filteredArticleSummaries : 